import 'react-app-polyfill/ie11';
import 'core-js/stable';
import { eraseCookie } from '../util/cookieActions';


  export const removeDLCookies = () => {
    eraseCookie(window.config.businessUnit + '-Token');
    eraseCookie(window.config.businessUnit + '-Conversation');
    eraseCookie(window.config.businessUnit + '-botMaximise');
    eraseCookie(window.config.businessUnit + '-AuthToken');
    eraseCookie(window.config.businessUnit + '-OldCoversation');
    eraseCookie(window.config.businessUnit + '-Language');
    eraseCookie(window.config.businessUnit + '-EnableSendBox');
    eraseCookie(window.config.businessUnit + '-BotLastResponseTime');
    eraseCookie(window.config.businessUnit + '-botMaximiseTime');
    eraseCookie(window.config.businessUnit + '-CheckOrderLob');
    eraseCookie(window.config.businessUnit + '-CheckOriginalId');
    eraseCookie(window.config.businessUnit + '-WebPopup');
  };

