import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, {useState, useCallback, useContext} from 'react';
import ArticleModal from './ArticleModal';
import { AppContext } from "../util/appContext";
import { Components } from 'botframework-webchat-component';
var classNames = require('classnames');


const ArticleButton = ({content}) =>{
    const [showArticleModal, setArticleModal] = useState(false);
    const context = useContext(AppContext);

    var articleButton = classNames({
        'btn-secondary': true,
        'article-button':true

    })

    const handleArticleModalClose = useCallback( event => {
        context.setMenuOpen(false);
        if(showArticleModal){
            window.parent.postMessage({key:'display_article_popup', origin: window.location.origin}, window.config.pageOrigin);
        }else {
            window.parent.postMessage({key:'hide_article_popup', origin: window.location.origin}, window.config.pageOrigin);
        }
       
        setArticleModal(!showArticleModal);

    },[showArticleModal,setArticleModal]);
    return(
        <div className="heroCardContainer">
          {!window.config.isNewUI &&  <div class="Icon"></div>}
           <div className="article-button-container">
               <button onClick={handleArticleModalClose} className={articleButton}>{content.buttons.title}</button>
           </div>
           {showArticleModal?<ArticleModal article={content} handleArticleModalClose={handleArticleModalClose} />:null}
        </div>
    )
}

export default ArticleButton;

