import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useState, useCallback, useContext } from "react";
import { hooks } from "botframework-webchat-component";
import { AppContext } from "../util/appContext";
import {setCookie, getCookie, eraseCookie} from '../util/cookieActions';

var classNames = require("classnames");

const { useSendPostBack, useSendMessage, useSendEvent } = hooks;

const KBFilters = ({ content, store }) => {
  var orderDetailBodytClass = classNames({
    orderDetailBody: true
  });
  const [productType, setProductType] = useState('');
  const [deviceModel, setDeviceModel] = useState('');
  const [customIssueDetailDisplay, setCustomIssueDetailDisplay] = useState(false);
  const [issueCategory, setIssueCategory] = useState('');
  const [customIssueDetail, setCustomIssueDetail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [otherOptions, setOtherOptions]= useState('');
  const [otherOptionsDisplay, setOtherOptionsDisplay]= useState(false);
  const [deviceModelDisplay, setDeviceModelDisplay] = useState(true);
  const [issueCategoryDisplay, setIssueCategoryDisplay] = useState(true);
  
  const context = useContext(AppContext);
  const sendPostBack = useSendPostBack();
  const sendMessage = useSendMessage();
  const sendEvent = useSendEvent();

  const onClick = useCallback(
    value => event => {
      event.preventDefault();
      setIsSubmitted(true);
      
      sendPostBack(`${productType}-${deviceModel}-${issueCategory}-${customIssueDetail}-${otherOptions}`);
      context.setMenuOpen(false);
      if(getCookie(window.config.businessUnit + '-EnableSendBox')) {
        sendEvent('enableSendBoxEventReceived');
      }
      context.setDisableSendBox(true);
    }, [
      sendPostBack,
      productType,
      deviceModel,
      issueCategory,
      customIssueDetail,
      otherOptions,
      setIsSubmitted,
    ]
  );
  const onChange = useCallback(
    value => event => {
      event.preventDefault();
      switch (event.target.id) {
        case 'product_type':
          setProductType(event.target.value);
          if (event.target.value === 'Other: Gas detection, PPE, thermostat') {
            setDeviceModelDisplay(false);
            setIssueCategoryDisplay(false);
            setOtherOptionsDisplay(true);
            setCustomIssueDetailDisplay(false);
          } else {
            setDeviceModelDisplay(true);
            setIssueCategoryDisplay(true);
            setOtherOptionsDisplay(false);
          }
          break;
        case 'device_model':
          setDeviceModel(event.target.value);
          break;
        case 'issue_category':
          setIssueCategory(event.target.value);
          // if (event.target.value === 'other') {
          //   setCustomIssueDetailDisplay(true);
          // } else {
          //   setCustomIssueDetailDisplay(false);
          // }
          setCustomIssueDetailDisplay(true);
          break;
        case 'custom_issue_detail':
          setCustomIssueDetail(event.target.value);
          break;
        case 'Other_options':
          setOtherOptions(event.target.value);
          break;
      }
    
      context.setMenuOpen(false);
      if(getCookie(window.config.businessUnit + '-EnableSendBox')) {
        sendEvent('enableSendBoxEventReceived');
      }
      context.setDisableSendBox(true);
    }, [
      setCustomIssueDetail,
      setDeviceModel,
      setProductType,
      setIssueCategory,
      setCustomIssueDetailDisplay,
      setOtherOptions,
      setOtherOptionsDisplay,
      setDeviceModelDisplay,
      setIssueCategoryDisplay
    ]
  );
  const staticContent = {
    header: 'Header',
    inputs: [
      {
        type: 'select',
        label: 'Product Type',
        for: 'product_type',
        dependent: true,
        value: '',
        options: [
          {
            display: 'Please select Product Type',
            value: '',
          },
          {
            display: 'Scanners', 
            value: 'Scanners'
          },
          {
            display: 'Printer',
            value: 'Printer'
          },
          {
            display: 'Mobile Device', 
            value: 'Mobile Device'
          },
          {
            display: 'Other: Gas detection, PPE, thermostat',
            value: 'Other: Gas detection, PPE, thermostat'
          }
        ],
        id: "product_type",
        name: 'product_type',
        hidden: false,
        autoComplete: false,
      },
      {
        type: 'text',
        label: 'What is the model of your device?',
        for: 'device_model',
        placeholder: 'Device Model',
        value: '',
        id: "device_model",
        name: 'device_model',
        hidden: true,
        autoComplete: false,
      },
      {
        type: 'select',
        label: 'Issue Category',
        for: 'issue_category',
        value: '',
        onSelect: true,
        options: [
          {
            display: 'Please select issue category',
            value: ''
          },
          {
            display: 'Not turning on, not responding',
            value: 'Not turning on, not responding'
          },
          {
            display: 'Error message',
            value: 'Error message'
          },
          {
            display: 'Configure Settings',
            value: 'Configure Settings'
          },
          {
            display: 'Firmware upgrade / downgrade',
            value: 'Firmware upgrade / downgrade'
          },
          {
            display: 'Manual / User’s guide',
            value: 'Manual / User’s guide'
          },
          {
            display: 'Part Number',
            value: 'Part Number'
          },
          {
            display: 'Network issues',
            value: 'Network issues'
          },
          {
            display: 'Installation',
            value: 'Installation'
          },
          {
            display: 'Licenses',
            value: 'Licenses'
          },
          {
            display: 'Other',
            value: 'other'
          }
        ],
        id: "issue_category",
        name: 'issue_category',
        hidden: true,
        autoComplete: false,
      },
      {
        type: 'button_list',
        
        for: 'other_options',
        value: '',
        onSelect: true,
        options: [

          {
            display: 'Gas Detection Support',
            value: "https://sps-support.honeywell.com/s/hgas",
            
          },
          {
            display: 'PPE Support',
            value: "https://sps-support.honeywell.com/s/ppe",
            
          },
          {
            display: 'AST Support',
            value: "https://sps-support.honeywell.com/s/ast",
            
          },
          {
            display: 'Vocollect Support',
            value: "https://support.honeywellaidc.com/s/article/How-to-contact-the-Vocollect-Voice-Solutions",
            
          },
          {
            display: 'Inteligrated Support',
            value: "https://www.intelligrated.com/en",
            
          },
          {
            display: 'Thermostat Support',
            value: "https://www.honeywellhome.com/us/en/support?utm_source=vanityurl&utm_medium=print&utm_campaign=en/support",
            
          },
        ],
        id: "other_options",
        name: 'other_options',
        hidden: true,
        autoComplete: false,
      },
      {
        type: 'text',
        label: 'Note:Use Keywords and short sentences',
        for: 'custom_issue_detail',
        placeholder: 'Share your issue details',
        value: '',
        id: "custom_issue_detail",
        name: 'custom_issue_detail',
        hidden: true,
        autoComplete: false,
      },
    ],
  };

  const rows = [];
  for (let input of staticContent.inputs) {
    let element;
    switch (input.type) {
      case 'text':        
        element = <input autoComplete={input.autoComplete ? "on" : "off"} onBlur={onChange(input)} placeholder={input.placeholder} type={input.hidden && ((!customIssueDetailDisplay && input.id == 'custom_issue_detail')|| (!otherOptionsDisplay && input.id == 'other_options')|| (!issueCategoryDisplay && input.id == 'issue_category')|| (!deviceModelDisplay && input.id == 'device_model'))  ? 'hidden' : input.type} id={input.id} name={input.name} />;
        break;
      case 'select':
        const options = [];
        for (let option of input.options) {
          options.push(<option value={option.value}>{option.display}</option>);
        }
        element = <select autoComplete={input.autoComplete ? "on" : "off"} id={input.id} onChange={onChange(input)} placeholder={input.placeholder}>
          {options}
        </select>;
        break;
        case 'button_list' :
          const buttons = [];
          buttons.push(<span>Please select a contact option below :</span>);
          buttons.push(<br/>)
          buttons.push(<br/>)
          for (let option of input.options)
          {
            buttons.push('\u00A0');
            buttons.push('\u00A0');;
            buttons.push('\u00A0');
            buttons.push('\u00A0');
            buttons.push(<button  type="button"><a href={option.value} target='_blank'>{option.display}</a></button>);
            buttons.push(<br/>);
            buttons.push(<br/>);
            
          }
          buttons.push(<br/>);
          element = buttons;
            
        }
        const label =  input.id != 'other_options' ?
        (<label for={input.for}>
            <span>{input.label}</span>
          </label>): ''
          const br =  input.id != 'other_options' ? <br />: ''
    rows.push(<p className={input.hidden && ((!customIssueDetailDisplay && input.id == 'custom_issue_detail')|| (!otherOptionsDisplay && input.id == 'other_options')|| (!issueCategoryDisplay && input.id == 'issue_category')|| (!deviceModelDisplay && input.id == 'device_model'))  ? 'hide' : ''}>
       {label}{br}
      {element}
      <br /><br />
    </p>);
  }
  return (
    <div>
      <ul className="suggestedAction">
        <div class="botTextBox conversationTextBox">
          <div class="conv-text">
        {rows}
            <button className={!isSubmitted && !otherOptionsDisplay ? '' : 'hide'} type="button" onClick={onClick()}>Submit</button>
          </div>
        </div>
      </ul>
    </div>
  );
};

export default KBFilters;
