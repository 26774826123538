import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, {useState, useCallback, useContext,useEffect} from 'react';
import { AppContext } from "../util/appContext";
import { hooks } from "botframework-webchat-component";
import Select from "react-select";
const classNames = require('classnames');
const { useSendEvent } = hooks;
const { useSendPostBack } = hooks;

const LiveAgentDropDown = ({content,userLanguage}) => {
    const context = useContext(AppContext);
    const sendEvent = useSendEvent();
    const sendPostBack = useSendPostBack();
    const [isLALanguageDefault, setIsLALanguageDefault] = useState(null);
    const [isLALanguage, setIsLALanguage] = useState(null);
    const [isLALanguageLabel, setIsLALanguageLabel] = useState(null);
   useEffect(() => {
    let selectedData=[];
    if(window.config.businessUnit === "PMT-AMRC"){
    selectedData=content?.languageArray.filter(x=>x.value==userLanguage);
    }
    else{
    if(window.config.language=="en_GB"){
      selectedData=content?.languageArray.filter(x=>x.value=="en");
    }
    else{
      selectedData=content?.languageArray.filter(x=>x.value==window.config.language);
    }
    }
   // console.log(selectedData,"..selectedData..");
    if(selectedData?.length>0){
    setIsLALanguageDefault(selectedData[0]);
    setIsLALanguage(selectedData[0].value);
    setIsLALanguageLabel(selectedData[0].label);
    }
   // console.log(isLALanguage,"..isLALanguage..",isLALanguageLabel);

  }, []);
    const handleChangeLanguage=(event)=>{
     // console.log(event,"..event")
        setIsLALanguageDefault(event);
        setIsLALanguage(event.value);
        setIsLALanguageLabel(event.label);
      }
    const handleSubmitClick=()=>{
    //console.log(isLALanguage,"..isLALanguage");
    document.getElementById(content.id).style.display="none";
    if(window.config.isLACTranslationEnabled){
        if(isLALanguage){
          const submitObject = {
            "liveAgentLanguage": isLALanguage,
            "liveAgentLanguageLabel": isLALanguageLabel,
            "id":content.id
        };
       sendPostBack(submitObject); 
        }
        else{
          const submitObject = {
            "liveAgentLanguage": "en",
            "liveAgentLanguageLabel": "English",
            "id":content.id
        };
       sendPostBack(submitObject); 
        }
      }
    }

    return(
      <><div id={content.id}>{window.config.isLACTranslationEnabled?<div className="kBForm kbFormCustomMarginLA">
      <div className="webformInputLabel">
          <span className="webformFieldTitle kBFields">{content.title}:</span>
      </div>
      <div className="input-wrap">
         <Select
  className="input-wrap-text-plugin kBFieldSelect"
  name="laLanguageDropdown"
  id="laLanguageDropdown"
  value={isLALanguageDefault}
  options={content.languageArray}
  placeholder={content.placeHolder?content.placeHolder:"Select language"}
  onChange={(e) => handleChangeLanguage(e)}
  isSearchable={true} />
      </div>
      <div className="kBFormSubmitDiv">
                    <button
                       onClick={handleSubmitClick}
                        id={"liveAgentId"}
                        className="webformButton primary kBFormSubmit"
                        disabled={!isLALanguage}>
                        {content.buttonText?content.buttonText:"Confirm"}</button>
                </div>
      <br/>
  </div>:null}</div></>


    )
}

export default LiveAgentDropDown;