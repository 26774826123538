import "react-app-polyfill/ie11";
import "core-js/stable";
import { hooks } from "botframework-webchat-component";
import setLastMessage from "../util/setLastMessage";
import React, {useState, useCallback, useContext} from 'react';
import { AppContext } from "../util/appContext";
import NewArticleModal from './NewArticleModal';
import WEB from '../images/svgLatest/Web.svg';
import {setCookie, getCookie, eraseCookie} from '../util/cookieActions';

var classNames = require("classnames");

const { useSendMessage, useSendEvent } = hooks;

const NewHeroCard = ({ content }) => {
  const sendMessage = useSendMessage();
  const context = useContext(AppContext);
  const sendEvent = useSendEvent();

  const handleClick = useCallback(
    value => event => {
      event.preventDefault();
      if(getCookie(window.config.businessUnit + '-EnableSendBox')) {
        sendEvent('enableSendBoxEventReceived');
      }
      context.setDisableSendBox(true);
      setLastMessage(value);
      sendMessage(value);
      context.setMenuOpen(false);
      content.buttons.map((item,index) => {
        if (item.button.type !== "Articlebutton" &&  item.button.type !== "openResultUrl")
        {
           content.buttons.splice(index);
        }
      });
    },
    [sendMessage]
  );


  const [showArticleModal, setArticleModal] = useState(false);
  const [articleNumber, setArticleNumber] = useState(-1);

  var articleButton = classNames({
      'btn-secondary': true,
      'article-button':true

  });

  window.onmessage = (e) => {
    switch (e.data.key) {
      case 'display_article_popup':
        document.getElementById('root').classList.remove('root-min');
        break;
      case 'hide_article_popup':
        document.getElementById('root').classList.add('root-min');
        break;
      default:
        break;
    }
  };

  const handleArticleModalClose = useCallback(  (actionItem) => event => {
      setArticleNumber(actionItem);
      context.setMenuOpen(false);
      if(showArticleModal){
        window.parent.postMessage({key:'display_article_popup', origin: window.location.origin}, window.config.pageOrigin);
      }else {
        window.parent.postMessage({key:'hide_article_popup', origin: window.location.origin}, window.config.pageOrigin);
      }
     
      setArticleModal(!showArticleModal);

  },[showArticleModal,setArticleModal,setArticleNumber]);

  var heroCardButton = classNames({
    heroCardButton: true,
    customscrollbar: true
  });

  return (
    <div className="heroCardContainer">
      {/* <div className={"botAvatarClass"}>
        <Components.Avatar height={20} fromUser={false} />
      </div> */}
      <div className="heroCard">
        {/* <div className="heroCardTitle">
          <p>{content.Title}</p>
        </div> */}
        <div className={heroCardButton}>
          {content.buttons.length > 0 &&
            content.buttons.map((item,index) => {
              if(item.button.type ==='Articlebutton')
              {
                return(   
                    <button onClick={handleArticleModalClose(index)}>{item.button.title}</button>
                    )
                }
              if (item.button.type === "imBack") {
                return (
                  <button onClick={handleClick(item.button.value)}>
                    {item.button.title}
                  </button>
                );
              } 
              if (item.button.type === "openResultUrl") {
                return (
                  <button onClick={(e) => {
                    e.preventDefault();
                    window.open(item.button.value, "_blank");
                  }}>
                    {item.button.title}
                  </button>
                )
              }
              return "";
            })}
            </div>
            {content.buttons.length > 0 && <div className="extraButtons" >
            {content.buttons.map(item => {
              if (item.button.type === "openUrl") {
                return (
                  <a href={item.button.value} target="_blank" class="viewMoreLink" style={{backgroundColor:'#007BC2'}}>
                    <img src={WEB} alt="web" />
                    <p>{item.button.title}</p>  
                  </a>
                );
              }
              else if (item.button.type === "extra") {
                return (
                  <button onClick={handleClick(item.button.value)}>
                  {item.button.title}
                </button>
                )
              }
            })
            }
            {showArticleModal?<NewArticleModal article={content.buttons[articleNumber]} handleArticleModalClose={handleArticleModalClose} />:null}

          </div>

         }
        <div >


         
        </div>
      </div>
    </div>
  );
};

export default NewHeroCard;