import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useState, useCallback, useContext } from "react";
import surveyBackGround from "../images/Feeback_BG.png";
import { hooks } from "botframework-webchat-component";
import StaticLabels from "../util/staticLabels.json";
import Rating from "./Rating";
import { AppContext } from "../util/appContext";
import Spinner from "./Spinner";
import HeaderLogo from "../images/svgLatest/HeaderLogo.svg";
import HeaderLogo_HBT from "../images/svgLatest/HeaderLogo_HBT.svg";
import {setCookie, getCookie, eraseCookie} from '../util/cookieActions';

const { useSendEvent } = hooks;

const SurveyCard = ({
  isMaximised,
  handleMaximizeChange,
  handleSurveyCardChange,
  handleSpinnerChange,
  userLanguage,
  handleModalChange,
  showSurvey,
  setHeaderIconsDisplay,
  surveyLink,
  setSatmatrixSurvey,
  setSatmatrixSurveyIFrame,
  isAgentInitiated
}) => {
  const sendMessage = useSendEvent();
  const [feedbackValue, setFeedbackValue] = useState(0);
  const [feedbackComment, setFeedBackComment] = useState("");
  const [isRatingSelected, setRatingSelected] = useState(false);
  const context = useContext(AppContext);

  const handleFeedBackValueChange = useCallback(
    (rating, ratingObject) => {
      var isSelected = ratingObject.every(
        ele => Object.values(ele)[1] === false
      );
      setRatingSelected(!isSelected);
      setFeedbackValue(rating + 1);
    },
    [setFeedbackValue, setRatingSelected]
  );

  const handleFeedBackCommentChange = useCallback(
    event => {
      const comment = event.target.value;
      setFeedBackComment(comment);
    },
    [setFeedBackComment]
  );

  const handleSurveySubmit = useCallback(
    event => {
      sendMessage("survey", { feedbackValue: feedbackValue, feedbackComment });
      context.setOptionSelected(false);
      context.setMoreOptionsClick(false);
      handleSurveyCardChange();
      sendMessage('updateTranscriptAndResolveCase');
      removeDLCookies();
      handleMaximizeChange();
      sessionStorage.removeItem("chatbotActive");
    },
    [
      feedbackValue,
      feedbackComment,
      sendMessage,
      handleSurveyCardChange,
      handleMaximizeChange
    ]
  );

  const handleSurveyLinkClick = useCallback(

    event => {  
     if(isAgentInitiated)    
     sendMessage("CancelSession");
     context.setMoreOptionsClick(false);
    // context.setSatmatrixSurvey(true);
     handleSurveyCardChange();
     sendMessage('updateTranscriptAndResolveCase');
     removeDLCookies();
     context.setShowGoBackbutton(true);
     context.setHideStickyMenu(false);
     handleMaximizeChange();
     context.setOptionSelected(false);
     sessionStorage.removeItem("chatbotActive");
    },
    [handleSurveyCardChange, handleMaximizeChange,handleSpinnerChange,sendMessage]
  );
  const handleSurveyLinkClick_Submit = useCallback(
    event => {
      if(isAgentInitiated) 
      sendMessage("CancelSession");
      context.setShowGoBackbutton(true);
      context.setOptionSelected(false);
      context.setHideStickyMenu(false);
      context.setMoreOptionsClick(false);
      sendMessage('updateTranscriptAndResolveCase');
      removeDLCookies();
      if(surveyLink==="")
      {
        sendMessage("GetSurveyLink");
        setSatmatrixSurvey(true);
      }
      setSatmatrixSurveyIFrame(true);
      sessionStorage.setItem(window.config.businessUnit + '-SatmatrixSurveyOpen', true);
  },[surveyLink,setSatmatrixSurvey,sendMessage,setSatmatrixSurveyIFrame]
    
  );

   const handleSurveyGoBackClick = useCallback(
    event => {      
     showSurvey(false);
     sendMessage("feedbackGoBackButton");
     context.setOptionSelected(true);
     context.setShowHeaderFooter(true);
     context.setEnableScroll(true);
     setHeaderIconsDisplay(true);
    }
  );
  
  const removeDLCookies = () => {
    if(getCookie(window.config.businessUnit + '-isPrivacyAccepted') && window.config.isNewPrivacy==true) {
      eraseCookie(window.config.businessUnit + '-isPrivacyAccepted');
    }
    if(getCookie(window.config.businessUnit + '-adjustResize') && window.config.isAdjustable==true) {
      eraseCookie(window.config.businessUnit + '-adjustResize');
    }
    eraseCookie(window.config.businessUnit + '-Token');
    eraseCookie(window.config.businessUnit + '-Conversation');
    eraseCookie(window.config.businessUnit + '-botMaximise');
    eraseCookie(window.config.businessUnit + '-AuthToken');
    eraseCookie(window.config.businessUnit + '-OldCoversation');
    eraseCookie(window.config.businessUnit + '-Language');
    eraseCookie(window.config.businessUnit + '-EnableSendBox');
    eraseCookie(window.config.businessUnit + '-BotLastResponseTime');
    eraseCookie(window.config.businessUnit + '-botMaximiseTime');
    eraseCookie(window.config.businessUnit + '-CheckOrderLob');
    eraseCookie(window.config.businessUnit + '-CheckOriginalId');
    eraseCookie(window.config.businessUnit + '-WebPopup');
  };
  // Commenting satmatrix survey functionality temporarily 
  // const handleSurveyLinkClick_Submit = useCallback(
  //   event => {
  //     sendMessage("GetSurveyLink");
  //     context.setSatmatrixSurvey(true);
  //   }
  // );
  return (
    <div style={{display:'contents'}}>
    <div className="header">
      <div className="ask-ellie">
      {window.config.isNewUI ? 
             <img
              className="launcherIconAvatar"
              alt="miniAvatar"
              src={HeaderLogo_HBT}
            /> : 
            <img
              className="launcherIconAvatar"
              alt="miniAvatar"
              src={HeaderLogo}
            />}
         { window.config.businessUnit === "HCE-TRIDIUM" ?  <h4>Tridium Assist</h4> : <h4>Honeywell Assist</h4>}
      </div>
    </div>
    <div className="surveyCardContainer">
      {/* {window.config.sbg === "AERO" ? (
        ""
      ) : (
        <div className="surveyCardImage">
          <img
            alt="survey card"
            height="100%"
            width="100%"
            src={surveyBackGround}
          ></img>
        </div>
      )} */}
      { window.config.sbg === "AERO" || window.config.sbg === "HBT" || window.config.sbg === "PMT"  || window.config.businessUnit === 'HCE-TRIDIUM'  || window.config.businessUnit === 'SPS-ECOMM' || window.config.businessUnit === 'SPS-PPR' || window.config.businessUnit === 'SPS-HIS' || window.config.businessUnit === 'SPS-GDM' || window.config.businessUnit === 'SPS-PSS' ? (
        <div className="surveyCard_Aero">
          <p>
           {surveyLink === "error" ? 
           (userLanguage ?
           StaticLabels[userLanguage.toLowerCase()].SURVEYERROR
           :
           "We're experiencing trouble loading the survey, please try again later. For persistent issues, contact us without hesitation")
           :
            (userLanguage ?  
              (window.config.businessUnit === "HCE-TRIDIUM" ? StaticLabels[userLanguage.toLowerCase()].SURVEYOPTIONTRIDIUM : StaticLabels[userLanguage.toLowerCase()].SURVEYOPTION)
              : 
              (window.config.businessUnit === "HCE-TRIDIUM" ? "To help improve our Tridium Virtual Assist, please tell us about your interaction today." : "To help improve our Honeywell Assist, please tell us about your interaction today."))}
          </p>
            <div className="sureveyCardButtons">
              <button
                className="btn-secondary skipSurveyLink"
                onClick={handleSurveyLinkClick}
              >
                {userLanguage
                ? StaticLabels[userLanguage.toLowerCase()].SKIPSURVEY
                : "SKIP & CLOSE"}
              </button>
              <button
                className="btn-primary skipSurveyLink goToSurvey"
                disabled={surveyLink === "error" ? true : false}
                onClick={() => {
                  handleSurveyLinkClick_Submit();
                }}
              >
                {userLanguage
                ? StaticLabels[userLanguage.toLowerCase()].GOTOSURVEY
                : "GO TO SURVEY"}
              </button>
              {context.showGoBackbutton ? <button
                className="btn-secondary skipSurveyLink"
                onClick={() => {
                  // context.setModalProps({ refresh: false, close: false, quitAgentQueue: false, busyNotification: false });
                  //   handleModalChange({isOpenModal:false})
                  handleSurveyGoBackClick();
                }}
              >
                {userLanguage
                ? StaticLabels[userLanguage.toLowerCase()].GoBack:"Go Back"}
              </button> : ""}
            </div>
        </div>
      ) : (
        <div className="surveyCard">
          <h1>
            {userLanguage
              ? StaticLabels[userLanguage.toLowerCase()].SURVEYOPINION
              : "YOUR OPINIONS MATTERS!"}
          </h1>
          <p>
            {userLanguage
              ? window.config.businessUnit === "SPS-LMT" ? StaticLabels[userLanguage.toLowerCase()].SURVEYOPTIONLMT: StaticLabels[userLanguage.toLowerCase()].SURVEYOPTION
              : "To help improve our Honeywell Assist, please tell us about your interaction today."}
          </p>
          <Rating handleFeedBackValueChange={handleFeedBackValueChange} />
          <div>
            <textarea
              onChange={handleFeedBackCommentChange}
              placeholder={
                userLanguage
                  ? StaticLabels[userLanguage.toLowerCase()].Comments
                  : "Comments"
              }
            ></textarea>
          </div>
          <div className="sureveyCardButtons" style={{margin: '0 1rem 1rem 1rem'}}>
            <button
              className="btn-secondary skipSurveyLink"
              onClick={handleSurveyLinkClick}
            >
              {userLanguage
                ? StaticLabels[userLanguage.toLowerCase()].SKIPSURVEY
                : "SKIP & CLOSE"}
            </button>
            <button
              className="btn-primary skipSurveyLink"
              disabled={!isRatingSelected}
              onClick={handleSurveySubmit}
            >
              {userLanguage
                ? StaticLabels[userLanguage.toLowerCase()].SUBMITSURVEY
                : "SUBMIT & CLOSE"}
            </button>
            {context.showGoBackbutton ? <button
                className="btn-secondary skipSurveyLink"
                onClick={() => {handleSurveyGoBackClick()}}
              >
                {userLanguage
                ? StaticLabels[userLanguage.toLowerCase()].GoBack:"Go Back"}
              </button> : ""}
          </div>
        </div>
      )}
    </div>
    </div>

  );
};

export default SurveyCard;
