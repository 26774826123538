import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, { useState, useContext, useCallback,useEffect } from 'react';
import HeaderLogo_HBT from "../images/svgLatest/HeaderLogo_HBT.svg";
import Close from "../images/svg/Close.svg";
var classNames = require("classnames");

const MyLink = ({ children, ...props }) => {
  return (
    <a style={{ fontSize: "12px", textDecoration: 'underline' }} {...props}>
      {children}
    </a>
  );
};





const LoadingErrorScreen =({handleModalChange,handleMaximizeChange,handleCloseModalPopup,marginTopSet}) => {
  // window.config.language="de";
  // window.config.businessUnit="HBT-GCEGBT";
  var headerClass = classNames({
    headerMax: true,
    header: true
  });
  
  return (
  <div className='nomarginpadding welcomePolicyDiv'>
      <div style={marginTopSet ? { marginTop:'-13px',padding:"0px"  } : { marginTop:'0px',padding:"0px" }}>
          <div className={headerClass}>
            <div className="ask-ellie">
              <img
                className="launcherIconAvatar"
                alt="miniAvatar"
                src={HeaderLogo_HBT}
              />
              <h4 style={{ fontWeight: '600' }}>Honeywell Assist</h4>
            </div>
            <ul>
              <li style={{ cursor: 'pointer' }}>
                <img alt="Close" title="Close Bot" src={Close} onClick={handleModalChange({
                  isOpenModal: false,
                  value: null,
                  target: "closeLaunchIcon"
                })}></img>
              </li>
            </ul>
          </div>
    </div>
    <p className='germanSection'>Our chatbot is temporarily unavailable. Please try again later</p>
  </div>
  )
}

export default LoadingErrorScreen;