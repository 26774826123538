import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, { useState, useContext, useCallback, useEffect } from 'react';
import ChatbotLogo from "../images/svgLatest/ChatbotLogo.svg";
import ChatbotLogo_HBT from "../images/svgLatest/ChatbotLogo_HBT.svg";
import { hooks } from "botframework-webchat-component";
import { AppContext } from "../util/appContext";
import Spinner from "./Spinner";
import Close from "../images/svg/Close.svg";
import Markdown from "markdown-to-jsx";
import StaticLabels from "../util/staticLabels.json";
import liveAgentStaticLabels from "../util/liveAgentStaticLabels.json";
import { setCookie, getCookie, eraseCookie } from '../util/cookieActions';
import HeaderLogo_HBT from "../images/svgLatest/HeaderLogo_HBT.svg";
import getCWACookies  from '../util/getCWACookies';

var classNames = require("classnames");

const MyLink = ({ children, ...props }) => {
  return (
    <a style={{ fontSize: "12px", textDecoration: 'underline' }} {...props}>
      {children}
    </a>
  );
};

function GreetUser() {
  const timeFormat = formatAMPM(new Date());
  if (timeFormat.ampm === 'AM') {
    // if(timeFormat.hour >=5 && timeFormat.hour <= 11){
    //   return "Good morning!"
    // }else{
    //   return "Good morning!"
    // }
    return "Good morning"
  } else {
    if (timeFormat.hour >= 4 && timeFormat.hour <= 7) {
      return "Good evening"
    }
    if (timeFormat.hour >= 1 && timeFormat.hour <= 4) {
      return 'Good afternoon'
    }

    if (timeFormat.hour === 12) {
      return 'Good afternoon'
    }

    if (timeFormat.hour > 7 && timeFormat.hour) {
      return "Good evening"
    }
  }
}

function GreetHBTUser() {
  const timeFormat = formatAMPM(new Date());
  if (timeFormat.ampm === 'AM') {
    return StaticLabels[window.config.language].GoodMorning;
  } else {
    if (timeFormat.hour >= 4 && timeFormat.hour <= 7) {
      return StaticLabels[window.config.language].GoodEvening;
    }
    if (timeFormat.hour >= 1 && timeFormat.hour <= 4) {
      return StaticLabels[window.config.language].GoodAfternoon;
    }

    if (timeFormat.hour === 12) {
      return StaticLabels[window.config.language].GoodAfternoon;
    }

    if (timeFormat.hour > 7 && timeFormat.hour) {
      return StaticLabels[window.config.language].GoodEvening;
    }
  }
}

function formatAMPM(date) {
  var hours = date.getHours();
  // var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // minutes = minutes < 10 ? '0'+minutes : minutes;
  // var strTime = hours + ':' + minutes + ' ' + ampm;
  return { hour: hours, ampm: ampm };
}


const WelcomeCard = ({ content, contentType, handleModalChange, contentPrivacy, handleMaximizeChange, handleCloseModalPopup,userLanguage  }) => {
  let languageSelection=window.config.businessUnit === "PMT-AMRC"?userLanguage ? userLanguage:"en":window.config.language?window.config.language:"en";
  if(window.config.businessUnit === 'SPS-ECOMM'){
  if(languageSelection === "en_GB"  || languageSelection === "ja") {
    languageSelection ="en";
  } else if(languageSelection ==="fr_CA") {
    languageSelection ="fr";
  } else if(languageSelection ==="es_CO") {
    languageSelection = "es";
  } 
  }
  const { useSendMessage, useSendPostBack, useSendEvent } = hooks;
  const [languageCardSelected, setLanguageCardSelected] = useState("");
  const context = useContext(AppContext);
  const sendMessage = useSendMessage();
  const sendEvent = useSendEvent();
  const sendPostBack = useSendPostBack();
  const pageName = context.pageTitleCookie;

  var homeMenuoptions = classNames({
    customscrollbar: true,
    homeContainerButtonXSBG: content?.buttons?.length > 4 ? true:false,
    marginHomeButtons: window.config.businessUnit === "HCE-MARKETING"
  });

  useEffect(() => {
    if(window.config.enabledForWelcome && window.config.language=="en" && getCWACookies("cwaSSOToken") && getCWACookies("cwaSSOToken") !== 'Anonymous'){
      context.setShowSendBox(true);
      context.setEnableScroll(true);
       context.setShowHeaderFooter(true);
    } 
  }, []);

  const handleButtonClick = (selectedOption) => {
    let welcomecardBotDiv = document.getElementsByClassName('welcomecardBotDivHeight');
    if (welcomecardBotDiv.length > 0 && contentType !== 'languageCard') {
      welcomecardBotDiv[0].style.height = 'auto';
    }
    if (contentType === "welcomeCard" && window.config.businessUnit != "HCE-MARKETING") {
      context.setOptionSelected(true);
      context.setShowHeaderFooter(true);
      context.setShowSendBox(true);
      context.setEnableScroll(true);
      context.setHideStickyMenu(false);
      context.setMoreOptionsClick(false);

      if (window.config.businessUnit === 'HBT-GCEGBT' || window.config.isPersisted) {
        setCookie(window.config.businessUnit + '-Conversation', true);
      }
      sendMessage(selectedOption);
    } else if (contentType === "welcomeCard" && window.config.businessUnit === "HCE-MARKETING") {
      context.setOptionSelected(true);
      context.setShowHeaderFooter(true);
      context.setShowSendBox(false);
      context.setEnableScroll(true);
      context.setHideStickyMenu(true);
      context.setSendWelcomeCard(false);
      window.parent.postMessage({ key: 'set_dp_cookie', value: 'false' }, window.config.pageOrigin);
      window.config.dp = "false";
      sendMessage(selectedOption);
    } else {
      sendPostBack(selectedOption);
      setLanguageCardSelected(contentType);
      context.setShowSpinnerWheel(true);
    }
  }

  const handleHBTNoThanks = useCallback(event => {
    sendEvent('SalesPagesNoThanks');
    handleCloseModalPopup();
    handleMaximizeChange();
  },
    [sendEvent, handleCloseModalPopup, handleMaximizeChange]
  );

  var headerClass = classNames({
    headerMax: true,
    header: true
  });
  var sendBoxDisableClass = classNames({
    'send-box-input': true,
    sendBoxInputHBT: true,
    disableSendBox: true
  });

  const liveAgentUnavailableMsg = () => {
    const lacUnavailable = content.buttons.some((buttonText, index) => (
      buttonText.toLowerCase() === liveAgentStaticLabels[window.config.language].LiveAgentUnavailable));
    if (lacUnavailable) {
      return (
        <div className='HBTLiveagentTextAlign'>
          {content.lacOperationalHoursMsg && <p className='HBTLiveagentFont'>{content.lacOperationalHoursMsg}</p>}
          {content.lacHolidaysMsg && <p className='HBTLiveagentFont'>{content.lacHolidaysMsg}</p>}
        </div>
      )
    }
  }

  const texts = content.text.split("/")

  const languageEnabled = window.config.language === 'en' || window.config.language === 'de' || window.config.language === 'fr' || window.config.language === 'it' || window.config.language === 'es' || window.config.language === 'nl'


  return (
    context.showSpinnerWheel === false ?
      !getCookie(window.config.businessUnit + '-Conversation') &&
      (context.optionSelected === false &&
        languageCardSelected !== contentType &&
        <div style={{ padding: '0px', marginTop: '-13px' }}>
       {(window.config.enabledForWelcome && window.config.language=="en" && getCWACookies("cwaSSOToken") && getCWACookies("cwaSSOToken") !== 'Anonymous')?null:<div className={headerClass}>
            <div className="ask-ellie">
              <img
                className="launcherIconAvatar"
                alt="miniAvatar"
                src={HeaderLogo_HBT}
              />
              <h4 style={{ fontWeight: '600' }}>Honeywell Assist</h4>
            </div>
            <ul>
              <li style={{ cursor: 'pointer' }}>
                <img alt="Close" title="Close Bot" src={Close} onClick={handleModalChange({
                  isOpenModal: false,
                  value: null,
                  target: "closeLaunchIcon"
                })}></img>
              </li>
            </ul>
          </div> }
          <div className="homeContainer">
            {/* <div className="closeBotDiv">
            <img alt="Close"  className="closeBot" title="Close Bot" src={Close} onClick={handleModalChange({
              isOpenModal: false,
              value: null,
              target: "closeLaunchIcon"
            })}></img>
          </div> */}
            <div className="homeContainerFixed">
              {/* {window.config.businessUnit === "HBT-GCEGBT" ? 
            <img alt="botIcon" src={ChatbotLogo_HBT} /> :
            <img alt="botIcon" src={ChatbotLogo} />
            }
              {window.config.businessUnit === "HBT-GCEGBT"  && window.config.language ?
                <p>{texts[0].replace("[GreetDCAUser]", `${GreetHBTUser()}`)}</p> :
                <p>{texts[0].replace("[GreetDCAUser]", `${GreetUser()}`)}</p>}
              {window.config.businessUnit === "HBT-GCEGBT" ? <div>
                <p>{texts[1]}<span>{texts[2]}</span> </p>
                <p>{texts[3]}</p>
                {contentPrivacy && <p className="contentPolicy"><Markdown
              options={{
                overrides: {
                  forceBlock: true,
                  forceInline: false,
                  a: {
                    component: MyLink,
                    target: "_blank",
                    props: {
                      className: "markdown-link",
                      target: "_blank"
                    }
                  }
                }
              }}
            >{contentPrivacy}</Markdown></p>}
                {content.salesMessage && <p className='HBTLiveagentMargin'>{content.salesMessage}</p>}
              </div> : <div>
                <p>{texts[1]}<span>{texts[2]}</span> </p>
                <p>{texts[3]}</p>
              </div> } */}
              <div style={{ marginBottom: '1rem' }} className="botTextBox conversationTextBox conversationTextBoxWelcomeMessage">
                <div className="conv-text welcomeMessage">
                {window.config.isNewPrivacy && contentType!="languageCard"?<>
                <p>{texts[0].replace("[GreetDCAUser]",languageSelection ? StaticLabels[languageSelection].welcomeShowText:StaticLabels["en"].welcomeShowText)+" "}{languageSelection ? StaticLabels[languageSelection].welcomeText:StaticLabels["en"].welcomeText}</p>
                <p className="margin_5">{languageSelection ? StaticLabels[languageSelection].chooseWelcome:StaticLabels["en"].chooseWelcome}</p></>:<p>{texts[0].replace("[GreetDCAUser]",`${GreetUser()}`)}</p>}
                {window.config.isNewPrivacy && contentType!="languageCard"? null:<><p>{texts[1]}<span>{texts[2]}</span> </p><p>{texts[3]}</p></>}
                </div>
              </div>
            </div>
            {window.config.businessUnit === 'SPS-GDM' || window.config.businessUnit === 'SPS-PSS' ?
              <div className={homeMenuoptions}>
                {content.buttons.map((item, index) => (
                  <button key={index} data-title={item.hoverText} className="btn-secondary" onClick={() => handleButtonClick(item.buttonText)}>{item.buttonText} </button>))}
              </div>
              :
              <div className={homeMenuoptions}>
                {content.buttons.map((buttonText, index) => (
                  <button key={index} className="btn-secondary" onClick={() => handleButtonClick(buttonText)}>{buttonText} </button>))}
              </div>}
            <div className='HBTLiveagentTextAlign'>
              {content.lacOperationalHoursMsg && <p className='HBTLiveagentFont'>{content.lacOperationalHoursMsg}</p>}
              {content.lacHolidaysMsg && <p className='HBTLiveagentFont'>{content.lacHolidaysMsg}</p>}
            </div>
            {/* <div className="policySection">
              {contentPrivacy && window.config.businessUnit !== "HBT-GCEGBT" && <p className="contentPolicy"><Markdown
                options={{
                  overrides: {
                    forceBlock: true,
                    forceInline: false,
                    a: {
                      component: MyLink,
                      target: "_blank",
                      props: {
                        className: "markdown-link",
                        target: "_blank"
                      }
                    }
                  }
                }}
              >{contentPrivacy}</Markdown></p>}
            </div> */}
          </div>
        </div>
      ) : (
        <Spinner
          isMaximised={true}
          showSpinner={true}
          handleModalChange={handleModalChange}
        />
      )
  )
}

export default WelcomeCard;