import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, {useState, useCallback, useContext} from 'react';
import { AppContext } from "../util/appContext";
import { hooks } from "botframework-webchat-component";
import CaseWebformModalADMRCHPSUOP from './CaseWebformModalADMRCHPSUOP';
import { getCookie, setCookie } from '../util/cookieActions';

const classNames = require('classnames');
const { useSendEvent } = hooks;

const CaseWebformCardPMTADMRCHPSUOP = ({content}) => {
    const context = useContext(AppContext);
    const sendMessage = useSendEvent();

    const articleButton = classNames({
        'btn-secondary': true,
        'article-button':true,
        'case-webform': true
    });
    if(context.showCaseWebFormArticleModal){
        window.parent.postMessage({key:'hide_article_popup', origin: window.location.origin}, window.config.pageOrigin);
    }
    window.onmessage = (e) => {
        switch (e.data.key) {
          case 'display_article_popup':
            document.getElementById('root').classList.remove('root-min');
            break;
          case 'hide_article_popup':
            document.getElementById('root').classList.add('root-min');
            break;
          default:
            break;
        }
      };

    const handleArticleModalSubmit = useCallback((event, comments, communicationMode, phoneNumber) => {
        context.setMenuOpen(false);
        if(context.showCaseWebFormArticleModal){
            window.parent.postMessage({key:'display_article_popup', origin: window.location.origin}, window.config.pageOrigin);
        }else {
            window.parent.postMessage({key:'hide_article_popup', origin: window.location.origin}, window.config.pageOrigin);
        }
        context.setCaseWebFormArticleModal(false);
        sendMessage('webformPopupSubmit', { webformComment: comments, preferredCommunicationMode: communicationMode + " " + phoneNumber });
        updateWebPopupCookie()
    },[]);

    const handleArticleModalClose = useCallback( event => {
        context.setMenuOpen(false);
        if(context.showCaseWebFormArticleModal){
            window.parent.postMessage({key:'display_article_popup', origin: window.location.origin}, window.config.pageOrigin);
        }else {
            window.parent.postMessage({key:'hide_article_popup', origin: window.location.origin}, window.config.pageOrigin);
        }
        context.setCaseWebFormArticleModal(false);
        sendMessage('webformPopupCancel', {});
        updateWebPopupCookie()


    }, []);
    
    const updateWebPopupCookie = () => {
        let history = getCookie(window.config.businessUnit + '-WebPopup');
        if(history){
        history = JSON.parse(history)
        let updatedHistory = [...history]?.map(i => i?.id === content?.popupId ? {
            ...i,
            isOpen:false
        } : i)
        setCookie(window.config.businessUnit + '-WebPopup', JSON.stringify(updatedHistory)) 
        }
    }
    
    return(
       <div className="heroCardContainer">
            {context.showCaseWebFormArticleModal?<CaseWebformModalADMRCHPSUOP article={content} handleArticleModalClose={handleArticleModalClose} handleArticleModalSubmit={handleArticleModalSubmit} />:null}
         </div>
        
       
    )
}

export default CaseWebformCardPMTADMRCHPSUOP;

